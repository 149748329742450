/* HeroSection.tsx */
import React from "react";
import HeroImageDesktop from "../assets/images/bff_hero_img_dt.jpg";
import HeroImageDesktop2x from "../assets/images/bff_hero_img_dt@2x.jpg";
import HeroImageDesktopAVIF from "../assets/images/bff_hero_img_dt.avif";
import HeroImageDesktopAVIF2x from "../assets/images/bff_hero_img_dt@2x.avif";
import HeroImageDesktopWebP from "../assets/images/bff_hero_img_dt.webp";
import HeroImageDesktopWebP2x from "../assets/images/bff_hero_img_dt@2x.webp";
import HeroImageMobile from "../assets/images/bff_hero_image_mb.jpg";
import HeroImageMobile2x from "../assets/images/bff_hero_image_mb@2x.jpg";
import HeroImageMobileAVIF from "../assets/images/bff_hero_image_mb.avif";
import HeroImageMobileWebAVIF2x from "../assets/images/bff_hero_image_mb@2x.avif";
import HeroImageMobileWebP from "../assets/images/bff_hero_image_mb.webp";
import HeroImageMobileWebP2x from "../assets/images/bff_hero_image_mb@2x.webp";
import SVGSprite from "../assets/images/sprites.svg";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <div className="relative w-full overflow-hidden min-h-hero md:h-hero px-4 bg-gradient-to-bl from-slate-50 to-rose-300">
      <picture>
        <source
          media="(max-width: 768px)"
          srcSet={`${HeroImageMobileAVIF} 1x, ${HeroImageMobileWebAVIF2x} 2x`}
          type="image/avif"
        />
        <source
          media="(max-width: 768px)"
          srcSet={`${HeroImageMobileWebP} 1x, ${HeroImageMobileWebP2x} 2x`}
          type="image/webp"
        />
        <source
          media="(max-width: 768px)"
          srcSet={`${HeroImageMobile} 1x, ${HeroImageMobile2x} 2x`}
          type="image/jpeg"
        />
        <source
          media="(min-width: 769px)"
          srcSet={`${HeroImageDesktopAVIF} 1x, ${HeroImageDesktopAVIF2x} 2x`}
          type="image/avif"
        />
        <source
          media="(min-width: 769px)"
          srcSet={`${HeroImageDesktopWebP} 1x, ${HeroImageDesktopWebP2x} 2x`}
          type="image/webp"
        />
        <source
          media="(min-width: 769px)"
          srcSet={`${HeroImageDesktop} 1x, ${HeroImageDesktop2x} 2x`}
          type="image/jpeg"
        />
        <img
          className="absolute top-0 left-0 object-cover md:object-center lg:object-top w-full h-full"
          src={HeroImageDesktop}
          alt="BFFMobile: A wireless network designed for families to thrive"
        />
      </picture>
      <div className="absolute top-12 left-5 sm:left-6 md:left-8 lg:top-13 lg:left-32 xl:left-36 2xl:left-40">
        <Link to="/">
          <svg height="39" width="216" aria-label="BFFMobile Logo">
            <use xlinkHref={`${SVGSprite}#bff_logo`}></use>
          </svg>
        </Link>
        <h1 className="mt-48 text-5xl font-bold text-white max-w-welcome-text">
          a wireless network{" "}
          <span className="text-deep-indigo">
            designed for families to thrive.
          </span>
        </h1>
      </div>
    </div>
  );
};

export default HeroSection;
