import React from "react";
import WelcomeImageDesktop from "../assets/images/bff_welcome_image_dt.jpg";
import WelcomeImageDesktop2x from "../assets/images/bff_welcome_image_dt@2x.jpg";
import WelcomeImageDesktopAVIF from "../assets/images/bff_welcome_image_dt.avif";
import WelcomeImageDesktopAVIF2x from "../assets/images/bff_welcome_image_dt@2x.avif";
import WelcomeImageDesktopWebP from "../assets/images/bff_welcome_image_dt.webp";
import WelcomeImageDesktopWebP2x from "../assets/images/bff_welcome_image_dt@2x.webp";
import WelcomeImageMobile from "../assets/images/bff_welcome_image_mb.jpg";
import WelcomeImageMobile2x from "../assets/images/bff_welcome_image_mb@2x.jpg";
import WelcomeImageMobileAVIF from "../assets/images/bff_welcome_image_mb.avif";
import WelcomeImageMobileAVIF2x from "../assets/images/bff_welcome_image_mb@2x.avif";
import WelcomeImageMobileWebP from "../assets/images/bff_welcome_image_mb.webp";
import WelcomeImageMobileWebP2x from "../assets/images/bff_welcome_image_mb@2x.webp";

const WelcomeSection = () => {
  return (
    <div className="flex items-start justify-center py-20 bg-white">
      <div className="container px-2 mx-auto lg:flex lg:items-center lg:max-w-welcome-lg xl:max-w-welcome-xl 2xl:max-w-welcome-2xl">
        <picture className="lg:flex-1 lg:max-w-welcome-image lg:ml-8">
          <source
            media="(max-width: 1279px)"
            srcSet={`${WelcomeImageMobileAVIF} 1x, ${WelcomeImageMobileAVIF2x} 2x`}
            type="image/avif"
          />
          <source
            media="(max-width: 1279px)"
            srcSet={`${WelcomeImageMobileWebP} 1x, ${WelcomeImageMobileWebP2x} 2x`}
            type="image/webp"
          />
          <source
            media="(max-width: 1279px)"
            srcSet={`${WelcomeImageMobile} 1x, ${WelcomeImageMobile2x} 2x`}
            type="image/jpeg"
          />
          <source
            media="(min-width: 1280px)"
            srcSet={`${WelcomeImageDesktopAVIF} 1x, ${WelcomeImageDesktopAVIF2x} 2x`}
            type="image/avif"
          />
          <source
            media="(min-width: 1280px)"
            srcSet={`${WelcomeImageDesktopWebP} 1x, ${WelcomeImageDesktopWebP2x} 2x`}
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet={`${WelcomeImageDesktop} 1x, ${WelcomeImageDesktop2x} 2x`}
            type="image/jpeg"
          />
          <img
            className="w-full max-w-welcome md:max-w-welcome-md lg:shadow-welcome-dt rounded-lg mx-8 mb-14"
            src={WelcomeImageDesktop}
            alt="Welcome to BFFMobile"
            loading="lazy"
          />
        </picture>
        <div className="mt-8 lg:max-w-welcome-text lg:mt-0 lg:ml-14 lg:flex-1 text-center lg:text-left">
          <h1 className="text-5xl font-bold text-bff-black">
            hi, welcome
            <br />
            to&nbsp;<span className="font-bold text-deep-indigo">bff</span>
            <span className="font-normal text-deep-indigo">mobile.</span>
          </h1>
          <p className="mt-4 text-sm font-light text-bff-text lg:max-w-welcome-text">
            We're a new wireless network built to be your best
            <br className="md:hidden tiny:hidden" /> friend. We're here to look
            out for you and your
            <br className="md:hidden tiny:hidden" /> family by keeping everyone
            connected, safe, and
            <br className="md:hidden tiny:hidden" /> secure in the simplest,
            healthiest way possible.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSection;
