// Home.js
import React from "react";
import HeroSection from "./HeroSection";
import BlackboxSection from "./BlackBoxSection";
import WelcomeSection from "./WelcomeSection";
import WhyDifferentSection from "./WhyDifferentSection";
import ValuesSectionParallax from "./ValuesSectionParallax";
import CapabilitiesSection from "./CapabilitiesSection";
import Footer from "./FooterSection";
import { initializeApp } from "firebase/app";
import useBodyClass from "../hooks/useBodyClass";

const Home = () => {
  document.title = "Welcome to BFFMobile";
  useBodyClass(["home", "show-heart"]);
  initializeApp({
    projectId: "bff-react-spa",
    apiKey: "AIzaSyB4mzEK2uPr9OnzDi5OXAAvGQQDYYc9XtU",
    authDomain: "bff-react-spa.web.app",
  });

  return (
    <>
      <HeroSection />
      <BlackboxSection />
      <WelcomeSection />
      <WhyDifferentSection />
      <ValuesSectionParallax />
      <CapabilitiesSection />
      <Footer />
    </>
  );
};

export default Home;
