import React, { useRef } from "react";
import SVGSprite from "../assets/images/sprites.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { createTimeline, highlightPhrase } from "./animations/valueSlides";

const DEBUG = false;

gsap.registerPlugin(useGSAP, ScrollTrigger);

type Rotation = "CW" | "CCW";

interface CapabilityProps {
  title: string;
  titleClass?: string;
  body: string;
  bodyClass?: string;
  ps: string;
  image?: string;
  alt: string;
  rotation?: Rotation;
  iconClass?: string;
  spriteTag?: string;
}

const Capability: React.FC<CapabilityProps> = (props) => {
  const rotation =
    props.rotation === "CCW" ? "rotate-capability-ccw" : "rotate-capability-cw";
  const headerClass = props.titleClass || "";
  const bodyClass = props.bodyClass || "";
  const iconClass =
    props.iconClass || "h-capability-card-icon max-w-capability-card-icon";
  const spriteTag = props.spriteTag || "";

  return (
    <>
      <section className="max-w-capability-card md:max-w-capability-card-dt lg:max-w-capability-card-lg xl:max-w-capability-card-xl">
        <div
          className={`inline-flex rounded-10 bg-black text-tech-green h-capability-card w-capability-card ${rotation} justify-self-center content-center items-center parallax`}
        >
          <svg
            className={`mx-auto select-none w-full max-h-full ${iconClass} draw-me`}
          >
            <use xlinkHref={`${SVGSprite}#${spriteTag}`}></use>
          </svg>
        </div>
        <h3 className={`pt-6 text-2xl font-bold mx-auto ${headerClass}`}>
          {props.title}
        </h3>
        <p className={`pt-6 font-light text-sm mx-auto ${bodyClass}`}>
          {props.body}
        </p>
        <p className="pt-4 font-medium text-sm">{props.ps}</p>
      </section>
    </>
  );
};

const CapabilitiesSection = () => {
  const container = useRef<HTMLDivElement | null>(null);

  useGSAP(
    () => {
      gsap.utils.toArray<HTMLElement>(".parallax").forEach((layer, index) => {
        if (layer instanceof HTMLHeadingElement) {
          createTimeline(
            container.current,
            "-50px bottom",
            "+=500 bottom",
            layer,
            index,
            {
              percentElXOffsetMax: 25,
              percentElXOffsetMin: 10,
              percentElYOffsetMax: 25,
              percentElYOffsetMin: 10,
            },
            {
              x: 0,
              y: 0,
            },
            undefined,
            undefined,
            true
          );
        } else {
          if (container.current) {
            const trigger: HTMLDivElement | null =
              container.current.querySelector(".from-capabilities");
            createTimeline(
              trigger,
              "top bottom",
              "bottom bottom",
              layer,
              index,
              {
                percentElXOffsetMax: 0,
                percentElXOffsetMin: 0,
                percentElYOffsetMax: 25,
                percentElYOffsetMin: 10,
              },
              {
                x: 0,
                y: 0,
              },
              undefined,
              undefined,
              true
            );
          }
        }
      });

      const tl = gsap.timeline();
      const highlight = container.current?.querySelector(".highlight") ?? null;
      tl.add(highlightPhrase(highlight, 0.2, 2), "<");

      ScrollTrigger.create({
        animation: tl,
        trigger: ".relative",
        start: "-175px center",
        end: "bottom",
        invalidateOnRefresh: true,
        markers: DEBUG
          ? {
              fontSize: "18px",
              fontWeight: "bold",
              startColor: "grey",
              endColor: "black",
            }
          : undefined,
      });
    },
    { scope: container, dependencies: [ScrollTrigger] }
  );

  return (
    <>
      <div ref={container}>
        <ul className="capabilities-section container md:max-w-screen-md mx-auto text-center font-bold text-4xl overflow-visible tiny:!text-[44px]">
          <li className="relative">
            <h2 className="pt-2 pb-12 relative parallax">
              <span
                data-highlightlen="344px"
                data-highlightlen-tiny="302px"
                className="highlight parallax"
                aria-hidden="true"
              ></span>
              <span className="inline-block">
                with bff<span className="font-light">mobile</span>
              </span>
              <br className="md:hidden" /> you can:
            </h2>
            <div className="mx-auto"></div>
          </li>
        </ul>

        <div className="bg-gradient-to-b from-deep-indigo from-capabilities to-pink to-100% py-16">
          <div className="p-4 lg:px-10 xl:px-32 w-full mx-auto grid justify-items-center grid-cols-1 md:grid-cols-3 text-center gap-y-20 md:gap-6 lg:gap-9 text-white">
            <Capability
              title="Choose from any of the 3 major networks."
              titleClass="max-w-capability-card-title md:max-w-capability-card-title-dt lg:max-w-capability-card-title-lg xl:max-w-capability-card-title-xl"
              body="Family members can be on different networks to ensure the best
            connectivity for everyone."
              bodyClass="max-w-network-text md:max-w-network-text-dt lg:md:max-w-network-text-dt"
              ps="And if you need to switch networks, no problem!"
              alt="Icon of a hand selecting items in a grid"
              spriteTag="choice"
            />

            <Capability
              title="Pick a data plan that fits your needs."
              titleClass="max-w-capability-card-title md:max-w-capability-card-title-dt lg:max-w-capability-card-title-lg xl:max-w-capability-card-title-xl"
              body="We all use our phones differently so you shouldn&rsquo;t have to pay for
            data you don&rsquo;t need."
              bodyClass="max-w-data-text md:max-w-data-text-dt"
              ps="Hint: Many families don&rsquo;t need an unlimited plan."
              alt="Icons for an array of devices (phone, tablet, smart TV, console, PC)"
              rotation="CCW"
              spriteTag="data_plan"
            />

            <Capability
              title="Determine the right balance of parental controls."
              titleClass="max-w-capability-card-title-long md:max-w-capability-card-title-dt lg:max-w-capability-card-title-long-lg xl:max-w-capability-card-title-long-xl"
              body="Select content filters, screen time limits, and other settings
            that make sense for the health and well-being of your family."
              bodyClass="max-w-controls-text"
              ps="Tools that put you in control"
              alt="Icon of volume sliders"
              spriteTag="controls"
            />

            <Capability
              title="Keep track of your loved ones."
              titleClass="max-w-capability-card-title md:max-w-capability-card-title-dt lg:max-w-capability-card-title-lg xl:max-w-capability-card-title-xl"
              body="Whether it&rsquo;s your kids, your elderly parents or community, stay
            up-to-date with everyone&rsquo;s location."
              bodyClass="max-w-loved-text"
              ps="No need for a separate app."
              alt="Icon of a group of people with a magnifying glass highlighting an individual"
              rotation="CCW"
              spriteTag="loved_ones"
            />

            <Capability
              title="Protect your phones from security threats."
              titleClass="max-w-capability-card-title-long md:max-w-capability-card-title-dt lg:max-w-capability-card-title-long-lg xl:max-w-capability-card-title-long-xl"
              body="Enhanced security protocols built into our service provide an extra level of comfort."
              bodyClass="max-w-security-text"
              ps="You&rsquo;ve got more important things to think about."
              alt="Icon of a badge with WiFi symbol emblazoned upon it"
              spriteTag="security"
            />

            <Capability
              title="Replace your phone screen if it gets damaged."
              titleClass="max-w-capability-card-title-long md:max-w-capability-card-title-dt lg:max-w-capability-card-title-long-lg xl:max-w-capability-card-title-long-xl"
              body="Because phones go with us everywhere, it&rsquo;s not a surprise that
            your screen can get cracked."
              bodyClass="max-w-damage-text"
              ps="We&rsquo;ll fix it quickly and at no cost to you."
              alt="Icon of a phone with cracked screen"
              rotation="CCW"
              iconClass="max-w-capability-card-icon-tall"
              spriteTag="phone_cracked"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CapabilitiesSection;
