import React from "react";

interface DividerProps {
  count?: number;
}

const DividerSection: React.FC<DividerProps> = ({ count = 8 }) => {
  const dots = new Array(count)
    .fill(0)
    .map((dot, index) => (
      <li key={index} className="flex-none ml-1.5 h-1 w-1 -mt-2.5"></li>
    ));
  return (
    <ul className="my-12 h-1 list-disc text-sm text-tech-green flex justify-center select-none">
      {dots}
    </ul>
  );
};

export default DividerSection;
