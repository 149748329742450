import React from "react";
import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setEmail,
  setName,
  setError,
  setSuccessMsg,
  selectForm,
  resetForm,
} from "../store/formSlice";
import { useEventCallback } from "usehooks-ts";
import { getFunctions, httpsCallable } from "firebase/functions";
import useReCaptcha from "../hooks/useReCaptcha";

const BlackboxSection = () => {
  const [isFormSubmitting, setFormSubmitting] = useState<boolean>(false);
  const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha();
  // disable warning about returning root state
  const state = useSelector(selectForm, {
    devModeChecks: {
      identityFunctionCheck: "never",
    },
  });
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement | null>(null);
  const handleSubmit = useEventCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setFormSubmitting(true);

      const token = await generateReCaptchaToken("signup");
      const functions = getFunctions();
      const sendEmail = httpsCallable(functions, "signUpEmail");
      sendEmail({
        email: state.email,
        name: state.name,
        token,
      })
        .then((result) => {
          dispatch(resetForm());
          dispatch(setSuccessMsg(`Waitlist joined`));
          setTimeout(() => {
            const msgs = document.querySelectorAll("p.error");
            if (msgs) {
              msgs.forEach((msg) => msg.classList.add("fadeOut"));
            }
          }, 5000);
        })
        .catch((err) => {
          dispatch(setError(`${err.code} - ${err.message} - ${err.details}`));
          dispatch(setSuccessMsg(null));
        })
        .finally(() => setFormSubmitting(false));
    }
  );

  return (
    <div className="flex justify-center w-full bg-bff-black pt-14 lg:pt-0">
      <div className="flex flex-col items-center w-full px-7 gap-9 md:max-w-4xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl lg:flex-row lg:justify-center pb-black-box-section-mb lg:py-black-box-section-dt">
        <section className="w-full lg:flex lg:flex-col lg:justify-center lg:flex-1">
          <h2 className="text-5xl font-bold text-white">
            join the<span className="lg:inline"> </span>
            <br className="lg:hidden" /> waitlist.
          </h2>
          <p className="mt-2 text-sm font-light text-white lg:mt-4">
            Initial service will be available by invitation only.
            <br />
            Give us your email so we can stay in touch!
          </p>
        </section>
        <div className="w-full lg:w-bff-form lg:flex-none">
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            className="flex flex-col items-start w-full lg:flex-row lg:items-center"
          >
            <div className="flex flex-col w-full gap-2 mb-4 lg:mb-0 lg:flex-1">
              <input
                autoComplete="given-name"
                onChange={(e) => dispatch(setName(e.target.value))}
                value={state.name}
                id="name"
                type="text"
                placeholder="Name"
                className="form-input"
                required
              />
              <input
                autoComplete="email"
                onChange={(e) => dispatch(setEmail(e.target.value))}
                value={state.email}
                id="email"
                type="email"
                placeholder="Email"
                className="form-input"
                required
              />
              {state.error != null && (
                <p className="error text-error">{state.error}</p>
              )}
              {state.success != null && (
                <p className="error text-deep-indigo">{state.success}</p>
              )}
            </div>
            <button
              type="submit"
              disabled={!reCaptchaLoaded || isFormSubmitting}
              className="waitlist lg:mt-0 lg:ml-4 lg:self-start"
            >
              sign up
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BlackboxSection;
