import { Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import SVGSprite from "../assets/images/sprites.svg";

import Home from "../components/Home";
const Privacy = lazy(() => {
  return import("../components/PrivacySection");
});
const Terms = lazy(() => {
  return import("../components/Terms");
});

export default function Router() {
  return (
    <Suspense
      fallback={
        <div className="relative min-w-screen min-h-screen">
          <svg className="w-spinner h-spinner absolute m-auto animate-spin absolute top-0 right-0 bottom-0 left-0 origin-center">
            <use xlinkHref={`${SVGSprite}#p-heart`}></use>
          </svg>
          <p className="text-3xl text-center absolute top-loading-message right-0 bottom-0 left-0">
            Loading&hellip;
          </p>
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Suspense>
  );
}
