import React from "react";
import DividerSection from "./DividerSection";

const WhyDifferentSection = () => {
  return (
    <>
      <div className="flex items-start justify-center text-center">
        <div className="container px-4 mx-auto items-center">
          <h1 className="text-5xl md:text-4xl font-bold text-bff-black">
            what makes
            <br className="md:hidden" />
            <span className="md:hidden">bff</span>
            <span className="font-normal md:hidden">mobile</span>{" "}
            <br className="md:hidden" />
            <span className="hidden md:inline">us </span>
            <span className="text-deep-indigo">
              different
              <br />
              from other
              <br className="md:hidden" /> carriers?
            </span>
          </h1>
          <DividerSection count={8}></DividerSection>
          <p className="mt-4 text-sm font-light text-bff-text leading-normal">
            Our affordable monthly plans include super-
            <br className="md:hidden" />
            reliable connectivity + <br className="hidden md:inline" />
            premium services designed <br className="md:hidden tiny:hidden" />
            to fit the unique needs of your family.
          </p>
        </div>
      </div>
    </>
  );
};

export default WhyDifferentSection;
