interface FormState {
  name: string;
  email: string;
  error: string | null;
  success: string | null;
}

const initialState: FormState = {
  name: "",
  email: "",
  error: null,
  success: null,
};

interface Action {
  type: string;
  payload: string;
}

const signupReducer = (state = initialState, action: Action) => {
  if (action.type === "form/setName") {
    return {
      ...state,
      name: action.payload,
    };
  } else if (action.type === "form/setEmail") {
    return {
      ...state,
      email: action.payload,
    };
  } else if (action.type === "form/setError") {
    return {
      ...state,
      error: action.payload,
    };
  } else if (action.type === "form/setSuccessMsg") {
    return {
      ...state,
      success: action.payload,
    };
  } else if (action.type === "form/resetForm") {
    return initialState;
  } else {
    return state;
  }
};

export default signupReducer;
