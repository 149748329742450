// hooks/useReCaptcha.ts

import { useEffect, useState } from "react";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;

const useReCaptcha = (): {
  reCaptchaLoaded: boolean;
  generateReCaptchaToken: (action: string) => Promise<string>;
} => {
  const [reCaptchaLoaded, setReCaptchaLoaded] = useState(false);

  // Load ReCaptcha script
  useEffect(() => {
    if (typeof window === "undefined" || reCaptchaLoaded) return;
    if (document.getElementById("gjs-captcha")) {
      setReCaptchaLoaded(true);
      return;
    }
    if (window.grecaptcha) {
      setReCaptchaLoaded(true);
      return;
    }
    const script = document.createElement("script");
    script.id = "gjs-captcha";
    script.async = true;
    script.src = `https://www.google.com/recaptcha/api.js?render=${CAPTCHA_SITE_KEY}`;
    script.addEventListener("load", () => {
      setReCaptchaLoaded(true);
    });
    document.body.appendChild(script);
  }, [reCaptchaLoaded]);

  // Get token
  const generateReCaptchaToken = (action: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!reCaptchaLoaded) return reject(new Error("ReCaptcha not loaded"));
      if (typeof window === "undefined" || !window.grecaptcha) {
        setReCaptchaLoaded(false);
        return reject(new Error("ReCaptcha not loaded"));
      }
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(CAPTCHA_SITE_KEY, { action })
          .then((token: string) => {
            localStorage.setItem("RECAPTCHA_TOKEN", token);
            resolve(token);
          });
      });
    });
  };

  return { reCaptchaLoaded, generateReCaptchaToken };
};

export default useReCaptcha;
