import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export const panelFade = (panel: Element | null) => {
  const tl = gsap.timeline();
  tl.fromTo(
    panel,
    { opacity: 0 },
    { immediateRender: false, opacity: 1, x: 0, duration: 2 }
  );
  return tl;
};

// fade image from 50% - 100% over 2s
export const imageFade = (image: Element | null) => {
  const tl = gsap.timeline();
  tl.fromTo(
    image,
    { opacity: 0.5 },
    { immediateRender: false, opacity: 1, duration: 2 }
  );
  return tl;
};

// at start of fade, begin highlighting line over 3s
export const highlightPhrase = (
  highlight: Element | null,
  delay: number = 0.5,
  duration: number = 2.75,
  ease: gsap.EaseString = "circ.inOut"
) => {
  const tl = gsap.timeline();
  gsap.to(highlight, { width: 0 });
  tl.fromTo(
    highlight,
    {
      width: 0,
    },
    {
      immediateRender: false,
      delay,
      duration,
      ease,
      width: (): string => {
        const tiny = window.matchMedia("(max-width: 320px)").matches;
        if (highlight) {
          if (tiny) {
            return highlight.getAttribute("data-highlightlen-tiny") || "105%";
          }
          return highlight.getAttribute("data-highlightlen") || "105%";
        }
        return "105%";
      },
    },
    "<"
  );
  return tl;
};

// at start of fade, begin scaling heart up
export const heartbeat = (heart: Element | null) => {
  const tl = gsap.timeline();
  gsap.to(heart, { scale: 0 });
  tl.fromTo(
    heart,
    {
      opacity: 0,
      scale: 0,
    },
    {
      immediateRender: false,
      opacity: 1,
      scale: 2,
      duration: 0.75,
    },
    "<"
  );
  // at end of previous scale, scale back to orig size
  tl.fromTo(
    heart,
    {
      scale: 2,
    },
    {
      immediateRender: false,
      scale: 1,
      duration: 0.75,
    },
    ">"
  );
  return tl;
};

const DEBUG = false;
const MAX_OFFSET_PCT = 15;

interface Offset {
  percentElXOffsetMax: number;
  percentElXOffsetMin?: number;
  percentElYOffsetMax: number;
  percentElYOffsetMin?: number;
}

const randomInt = (max: number = MAX_OFFSET_PCT, min?: number) => {
  if (min) {
    const minCeil = Math.ceil(min);
    const maxFloor = Math.floor(max);
    return Math.floor(Math.random() * (maxFloor - minCeil + 1) + minCeil);
  } else {
    return Math.floor(Math.random() * max);
  }
};

export const createTimeline = (
  trigger: HTMLElement | null,
  start: string | number | undefined,
  end: string | number | undefined,
  layer: HTMLElement,
  index: number,
  offsets: Offset,
  to: gsap.TweenVars,
  markerOpts?: boolean | ScrollTrigger.MarkersVars | undefined,
  elTypeForLog?: string,
  killOnComplete: boolean = false
) => {
  if (!trigger) {
    return;
  }
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger,
      start,
      end,
      scrub: true,
      markers: markerOpts || undefined,
      invalidateOnRefresh: true,
    },
    autoRemoveChildren: killOnComplete,
  });
  const layerRect = layer.getBoundingClientRect();
  if (layerRect) {
    const randomOffset = {
      x:
        layerRect.width *
          (randomInt(offsets.percentElXOffsetMax, offsets.percentElXOffsetMin) /
            100) *
          (0 === index % 2 ? 1 : -1) +
        "px",
      y:
        layerRect.height *
          (randomInt(offsets.percentElYOffsetMax, offsets.percentElYOffsetMin) /
            100) *
          (0 === index % 2 ? 1 : -1) +
        "px",
    };
    gsap.set(layer, randomOffset);
    if (DEBUG) {
      console.log(
        `set ${elTypeForLog} offsets to (${randomOffset.x},${randomOffset.y}) for layer`,
        layer
      );
    }
  }
  tl.to(layer, to, "<");
};

/**
 * Stolen from underscore.js
 * @private
 * @param {Int} times
 * @param {Function} func
 */

var after = function (times: number, func: Function) {
  return function () {
    if (--times < 1) {
      return func.apply(window, arguments);
    }
  };
};

export const svgInliner = (selector: string, cb: Function) => {
  var svgs = Array.from(document.querySelectorAll<SVGElement>(selector));
  var callback: (selector: string) => void = after(svgs.length, cb);

  svgs.forEach((svg) => {
    let src = "",
      svgId = "";

    // Don't run this on alerady-replaced SVGs
    if (svg.classList.contains("inlined-svg")) {
      return;
    }

    // Store some attributes of the image
    const attributes = svg.attributes;

    const externalRef = svg.querySelector("use");
    if (externalRef) {
      const attr = externalRef.attributes.getNamedItem("xlink:href");
      if (attr) {
        const splitAttrValue = attr.value.split("#");
        const validXlink = 2 === splitAttrValue.length;

        if (validXlink) {
          src = splitAttrValue[0];
          svgId = splitAttrValue[1];
        }
      }
    } else {
      src = svg.getAttribute("data-src") || "";
    }
    console.log(src);
    console.log(`${window.location.protocol}//${window.location.host}${src}`);

    // Get the contents of the SVG
    fetch(`${window.location.protocol}//${window.location.host}${src}`, {
      cache: "default",
      mode: "cors",
      headers: {
        "Content-Type": "text/xml",
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          return response.text();
        }
        return null;
      })
      .then((svgData) => {
        if (svgData) {
          // Setup a parser to convert the response to text/xml in order for it
          // to be manipulated and changed
          var parser = new DOMParser(),
            result = parser.parseFromString(svgData, "image/svg+xml");
          // console.dir(result);

          let inlinedSVG: SVGSVGElement | SVGSymbolElement | SVGElement | null =
            null;
          if (svgId) {
            const symbol = result.getElementById(
              svgId
            ) as unknown as SVGSymbolElement;

            if (symbol) {
              // const innerHTML = symbol.innerHTML;
              const newSVGElement = document.createElement(
                "svg"
              ) as unknown as SVGElement;
              // const newGElement = document.createElement(
              //   "g"
              // ) as unknown as SVGGElement;
              // newGElement.setAttribute("fill", "#cef424");

              while (symbol.childNodes.length) {
                newSVGElement.appendChild(symbol.childNodes[0]);
              }

              svg.appendChild(newSVGElement);
              // newSVGElement.appendChild(newGElement);

              const viewBox = symbol.getAttribute("viewBox");
              if (viewBox) {
                const dims = viewBox.split(" ");
                if (4 === dims.length) {
                  newSVGElement.setAttribute("width", dims[2]);
                  newSVGElement.setAttribute("height", dims[3]);
                }
              }

              // newSVGElement.innerHTML = innerHTML;
              // console.log(svgId, innerHTML);
              console.log(svgId, symbol);
              Array.from(symbol.attributes).forEach((attribute) => {
                if (attribute.name !== "src" && attribute.name !== "alt") {
                  newSVGElement.setAttribute(attribute.name, attribute.value);
                }
              });
              // const kids = Array.from(symbol.children);
              // kids.forEach((kid) => newSVGElement.append(kid));

              // inlinedSVG = newSVGElement;
              if (symbol && symbol.parentNode) {
                symbol.parentNode.replaceChild(newSVGElement, symbol);
                inlinedSVG = newSVGElement;
              }
            }
          } else {
            inlinedSVG = result.getElementsByTagName("svg")[0];
          }

          if (inlinedSVG) {
            // Remove some of the attributes that aren't needed
            inlinedSVG.removeAttribute("xmlns:a");
            inlinedSVG.removeAttribute("width");
            inlinedSVG.removeAttribute("height");
            inlinedSVG.removeAttribute("x");
            inlinedSVG.removeAttribute("y");
            inlinedSVG.removeAttribute("enable-background");
            inlinedSVG.removeAttribute("xmlns:xlink");
            inlinedSVG.removeAttribute("xml:space");
            inlinedSVG.removeAttribute("version");

            // Add in the attributes from the original <img> except `src` or
            // `alt`, we don't need either
            Array.from(attributes).forEach((attribute) => {
              if (
                attribute.name !== "src" &&
                attribute.name !== "alt" &&
                inlinedSVG
              ) {
                inlinedSVG.setAttribute(attribute.name, attribute.value);
              }
            });

            // Add an additional class to the inlined SVG to imply it was
            // infact inlined, might be useful to know
            inlinedSVG.classList.add("inlined-svg");

            // Add in some accessibility quick wins
            inlinedSVG.setAttribute("role", "img");

            // Replace the image with the SVG
            if (svg.parentNode) {
              svg.parentNode.replaceChild(inlinedSVG, svg);
            }

            // Fire the callback
            callback(selector);
          } else {
            console.error("No SVG to inline found");
          }
        } else {
          console.error("no svg data loaded");
        }
      })
      .catch((error) => {
        console.error(error);
        console.error("SVGs are not inlined, can not style / animate");
      });
  });
};
