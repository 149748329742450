import { useRef } from "react";
import GrantedImage from "../assets/images/1158012576.jpg";
import GrantedImageAVIF from "../assets/images/1158012576.avif";
import GrantedImageSmall from "../assets/images/1158012576_sm.jpg";
import GrantedImageSmallAVIF from "../assets/images/1158012576_sm.avif";
import GrantedImageSmallWebP from "../assets/images/1158012576_sm.webp";
import GrantedImageWebP from "../assets/images/1158012576.webp";
import HumanImage from "../assets/images/1141415668.jpg";
import HumanImageAVIF from "../assets/images/1141415668.avif";
import HumanImageSmall from "../assets/images/1141415668_sm.jpg";
import HumanImageSmallAVIF from "../assets/images/1141415668_sm.avif";
import HumanImageSmallWebP from "../assets/images/1141415668_sm.webp";
import HumanImageWebP from "../assets/images/1141415668.webp";
import TrustImage from "../assets/images/1348882857.jpg";
import TrustImageAVIF from "../assets/images/1348882857.avif";
import TrustImageSmall from "../assets/images/1348882857_sm.jpg";
import TrustImageSmallAVIF from "../assets/images/1348882857_sm.avif";
import TrustImageSmallWebP from "../assets/images/1348882857_sm.webp";
import TrustImageWebP from "../assets/images/1348882857.webp";
import YourBackImage from "../assets/images/638505908.jpg";
import YourBackImageAVIF from "../assets/images/638505908.avif";
import YourBackImageSmall from "../assets/images/638505908_sm.jpg";
import YourBackImageSmallAVIF from "../assets/images/638505908_sm.avif";
import YourBackImageSmallWebP from "../assets/images/638505908_sm.webp";
import YourBackImageWebP from "../assets/images/638505908.webp";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import {
  createTimeline,
  highlightPhrase,
  heartbeat,
} from "./animations/valueSlides";
import SVGSprite from "../assets/images/sprites.svg";

const MOBILE_BREAKPOINT = "(max-width: 767px)";
const DESKTOP_BREAKPOINT = "(min-width: 768px)";
const DEBUG = false;

gsap.registerPlugin(useGSAP, ScrollTrigger);

const RESET_X = {
  percentElXOffsetMax: 0,
  percentElXOffsetMin: 0,
};
const RESET_Y = {
  percentElYOffsetMax: 0,
  percentElYOffsetMin: 0,
};

const ValuesSectionParallax = () => {
  const container = useRef<HTMLDivElement | null>(null);

  useGSAP(
    () => {
      const panels = gsap.utils.toArray<HTMLDivElement>(".panel");
      const isMobile = window.matchMedia("(max-width: 640px)").matches;

      // lazy load "slides" and refresh ScrollTrigger as needed
      const lazyImages = Array.from(
        document.querySelectorAll("img[loading='lazy']")
      );
      lazyImages.forEach((img) => {
        img.addEventListener("load", () => ScrollTrigger.refresh());
      });

      gsap.utils.toArray<HTMLElement>(".parallax").forEach((layer) => {
        const panel: HTMLDivElement | null = layer.closest(".panel");
        let index = -1;
        if (panel) {
          index = panels.indexOf(panel);
        } else {
          return;
        }

        if (layer instanceof HTMLHeadingElement) {
          createTimeline(
            panel,
            "-50px bottom",
            "+=500 bottom",
            layer,
            index,
            {
              percentElXOffsetMax: 50,
              percentElXOffsetMin: 35,
              percentElYOffsetMax: isMobile ? 40 : 70,
              percentElYOffsetMin: isMobile ? 20 : 50,
            },
            {
              x: 0,
              y: 0,
              ease: "none",
            },
            DEBUG
              ? {
                  fontSize: "18px",
                  fontWeight: "bold",
                  startColor: "grey",
                  endColor: "black",
                }
              : undefined,
            DEBUG ? "h2" : undefined,
            true
          );
        } else if (
          layer instanceof HTMLImageElement ||
          layer instanceof HTMLPictureElement
        ) {
          createTimeline(
            panel,
            "-175px center",
            "+=700 center",
            layer,
            index,
            {
              percentElXOffsetMax: 25,
              percentElXOffsetMin: 10,
              percentElYOffsetMax: 25,
              percentElYOffsetMin: 10,
            },
            {
              x: 0,
              y: 3 === index ? "-3rem" : "-2.25rem",
              ease: "none",
            },
            DEBUG
              ? {
                  fontSize: "18px",
                  fontWeight: "bold",
                  startColor: "purple",
                  endColor: "orange",
                }
              : undefined,
            DEBUG ? "img" : undefined,
            true
          );
          // separate timeline for fadeout
          createTimeline(
            panel,
            "top",
            "bottom top",
            layer,
            index,
            {
              ...RESET_X,
              ...RESET_Y,
            },
            {
              ease: "none",
              opacity: 0,
            },
            DEBUG
              ? {
                  fontSize: "18px",
                  fontWeight: "bold",
                  startColor: "green",
                  endColor: "red",
                }
              : undefined,
            DEBUG ? "img.out" : undefined
          );
          createTimeline(
            panel,
            "-175px center",
            "+=700 center",
            panel,
            index,
            {
              ...RESET_X,
              ...RESET_Y,
            },
            {
              marginBottom: "1.5rex",
              ease: "none",
            },
            undefined,
            DEBUG ? "panel margin reduction" : undefined
          );
        } else if (layer.classList.contains("highlight")) {
          createTimeline(
            panel,
            "-300px top",
            "+=200 top",
            layer,
            index,
            {
              percentElXOffsetMax: 15,
              percentElXOffsetMin: 5,
              ...RESET_Y,
            },
            {
              x: 0,
              y: 0,
              ease: "none",
            },
            DEBUG
              ? {
                  fontSize: "18px",
                  fontWeight: "bold",
                  startColor: "blue",
                  endColor: "pink",
                }
              : undefined,
            DEBUG ? "highlight" : undefined
          );
        } else if (layer.classList.contains("heart-overlay")) {
          createTimeline(
            panel,
            "-100px top",
            "+=400 top",
            layer,
            index,
            {
              ...RESET_X,
              ...RESET_Y,
            },
            {
              x: 0,
              y: "1rem",
              ease: "none",
            },
            DEBUG
              ? {
                  fontSize: "18px",
                  fontWeight: "bold",
                  startColor: "green",
                  endColor: "red",
                }
              : undefined,
            DEBUG ? "hearts" : undefined
          );
          // separate timeline for fadeout
          createTimeline(
            panel,
            // nextPanel,
            "0% center",
            "100% top",
            layer,
            index,
            {
              ...RESET_X,
              ...RESET_Y,
            },
            {
              ease: "none",
              opacity: 0,
            },
            DEBUG
              ? {
                  fontSize: "18px",
                  fontWeight: "bold",
                  startColor: "purple",
                  endColor: "orange",
                }
              : undefined,
            DEBUG ? "hearts.out" : undefined
          );
        } else {
          console.warn(
            "Element is tagged as a layer, but has no timeline handler",
            layer
          );
        }
      });

      panels.forEach((section: HTMLDivElement, index: number) => {
        const panelTl = gsap.timeline();
        const highlight = section.querySelector(".highlight");
        const heart = section.querySelector(".heart-overlay");
        panelTl
          .add(highlightPhrase(highlight), "<")
          .add(heartbeat(heart), ">-2.5");

        ScrollTrigger.create({
          animation: panelTl,
          trigger: section,
          start: "-250px center",
          end: "+=700",
          invalidateOnRefresh: true,
          //   markers: true,
        });
      });
    },
    { scope: container, dependencies: [ScrollTrigger] }
  );

  return (
    <>
      <div className="valuesSection mb-4" ref={container}>
        <div className="scroll-container relative sm:min-h-screen mx-auto text-center font-bold text-4xl overflow-visible justify-center flex flex-col flex-wrap items-center w-scroll-container tiny:!text-[44px]">
          <section className="panel panel-1">
            <div className="box box-1">
              <h2 className="pb-12 relative parallax">
                <span
                  data-highlightlen="261px"
                  data-highlightlen-tiny="234px"
                  className="highlight parallax"
                  aria-hidden="true"
                ></span>
                <span className="inline-block">we'll never</span>
                <br className="lg:hidden" /> take you for
                <br className="lg:hidden" /> granted.
              </h2>
              <div className="mx-auto relative">
                <picture>
                  <source
                    type="image/avif"
                    media={MOBILE_BREAKPOINT}
                    srcSet={`${GrantedImageSmallAVIF} 1x, ${GrantedImageAVIF} 2x, ${GrantedImageAVIF} 3x`}
                  />
                  <source
                    type="image/webp"
                    media={MOBILE_BREAKPOINT}
                    srcSet={`${GrantedImageSmallWebP} 1x, ${GrantedImageWebP} 2x, ${GrantedImageWebP} 3x`}
                  />
                  <source
                    type="image/jpeg"
                    media={MOBILE_BREAKPOINT}
                    srcSet={`${GrantedImageSmall} 1x, ${GrantedImage} 2x, ${GrantedImage} 3x`}
                  />
                  <source
                    type="image/avif"
                    media={DESKTOP_BREAKPOINT}
                    srcSet={GrantedImageAVIF}
                  />
                  <source
                    type="image/webp"
                    media={DESKTOP_BREAKPOINT}
                    srcSet={GrantedImageWebP}
                  />
                  <source
                    type="image/jpeg"
                    media={DESKTOP_BREAKPOINT}
                    srcSet={GrantedImage}
                  />
                  <img
                    className="rotate-cw parallax"
                    src={GrantedImageSmall}
                    srcSet={GrantedImageSmall}
                    alt="we'll never take you for granted"
                    loading="lazy"
                  />
                </picture>
                <svg className="heart-overlay parallax">
                  <use xlinkHref={`${SVGSprite}#heart`}></use>
                </svg>
              </div>
            </div>
          </section>
          <section className="panel panel-2">
            <div className="box box-2">
              <h2 className="pb-12 relative parallax">
                <span
                  data-highlightlen="341px"
                  data-highlightlen-tiny="304px"
                  className="highlight parallax"
                  aria-hidden="true"
                ></span>
                <span className="inline-block">we'll treat you</span>
                <br className="lg:hidden" /> like a human.
              </h2>
              <div className="mx-auto relative">
                <picture>
                  <source
                    type="image/avif"
                    media={MOBILE_BREAKPOINT}
                    srcSet={`${HumanImageSmallAVIF} 1x, ${HumanImageAVIF} 2x, ${HumanImageAVIF} 3x`}
                  />
                  <source
                    type="image/webp"
                    media={MOBILE_BREAKPOINT}
                    srcSet={`${HumanImageSmallWebP} 1x, ${HumanImageWebP} 2x, ${HumanImageWebP} 3x`}
                  />
                  <source
                    media={MOBILE_BREAKPOINT}
                    srcSet={`${HumanImageSmall} 1x, ${HumanImage} 2x, ${HumanImage} 3x`}
                  />
                  <source
                    type="image/avif"
                    media={DESKTOP_BREAKPOINT}
                    srcSet={HumanImageAVIF}
                  />
                  <source
                    type="image/webp"
                    media={DESKTOP_BREAKPOINT}
                    srcSet={HumanImageWebP}
                  />
                  <source media={DESKTOP_BREAKPOINT} srcSet={HumanImage} />
                  <img
                    className="rotate-ccw parallax"
                    src={HumanImageSmall}
                    alt="we'll treat you like a human"
                    loading="lazy"
                  />
                </picture>
                <svg className="heart-overlay parallax">
                  <use xlinkHref={`${SVGSprite}#heart`}></use>
                </svg>
              </div>
            </div>
          </section>
          <section className="panel panel-3">
            <div className="box box-3">
              <h2 className="pb-12 relative parallax">
                <span
                  data-highlightlen="239px"
                  data-highlightlen-tiny="214px"
                  className="highlight parallax"
                  aria-hidden="true"
                ></span>
                <span className="inline-block">we'll earn</span>
                <br className="lg:hidden" /> your trust.
              </h2>
              <div className="mx-auto relative">
                <picture>
                  <source
                    type="image/avif"
                    media={MOBILE_BREAKPOINT}
                    srcSet={`${TrustImageSmallAVIF} 1x, ${TrustImageAVIF} 2x, ${TrustImageAVIF} 3x`}
                  />
                  <source
                    type="image/webp"
                    media={MOBILE_BREAKPOINT}
                    srcSet={`${TrustImageSmallWebP} 1x, ${TrustImageWebP} 2x, ${TrustImageWebP} 3x`}
                  />
                  <source
                    media={MOBILE_BREAKPOINT}
                    srcSet={`${TrustImageSmall} 1x, ${TrustImage} 2x, ${TrustImage} 3x`}
                  />
                  <source
                    type="image/avif"
                    media={DESKTOP_BREAKPOINT}
                    srcSet={TrustImageAVIF}
                  />
                  <source
                    type="image/webp"
                    media={DESKTOP_BREAKPOINT}
                    srcSet={TrustImageWebP}
                  />
                  <source media={DESKTOP_BREAKPOINT} srcSet={TrustImage} />
                  <img
                    className="rotate-cw parallax"
                    src={TrustImageSmall}
                    alt="we'll earn your trust"
                    loading="lazy"
                  />
                </picture>
                <svg className="heart-overlay parallax">
                  <use xlinkHref={`${SVGSprite}#heart`}></use>
                </svg>
              </div>
            </div>
          </section>
          <section className="panel panel-4">
            <div className="box box-4 mt-8">
              <h2 className="pb-12 relative parallax">
                <span
                  data-highlightlen="284px"
                  data-highlightlen-tiny="254px"
                  className="highlight parallax"
                  aria-hidden="true"
                ></span>
                <span className="inline-block">we'll always</span>
                <br className="lg:hidden" /> have your
                <br className="lg:hidden" /> back.
              </h2>
              <div className="mx-auto relative">
                <picture>
                  <source
                    type="image/avif"
                    media={MOBILE_BREAKPOINT}
                    srcSet={`${YourBackImageSmallAVIF} 1x, ${YourBackImageAVIF} 2x, ${YourBackImageAVIF} 3x`}
                  />
                  <source
                    type="image/webp"
                    media={MOBILE_BREAKPOINT}
                    srcSet={`${YourBackImageSmallWebP} 1x, ${YourBackImageWebP} 2x, ${YourBackImageWebP} 3x`}
                  />
                  <source
                    media={MOBILE_BREAKPOINT}
                    srcSet={`${YourBackImageSmall} 1x, ${YourBackImage} 2x, ${YourBackImage} 3x`}
                  />
                  <source
                    type="image/avif"
                    media={DESKTOP_BREAKPOINT}
                    srcSet={YourBackImageAVIF}
                  />
                  <source
                    type="image/webp"
                    media={DESKTOP_BREAKPOINT}
                    srcSet={YourBackImageWebP}
                  />
                  <source media={DESKTOP_BREAKPOINT} srcSet={YourBackImage} />
                  <img
                    className="rotate-ccw parallax"
                    src={YourBackImageSmall}
                    alt="we'll always have your back"
                    loading="lazy"
                  />
                </picture>
                <svg className="heart-overlay parallax">
                  <use xlinkHref={`${SVGSprite}#heart`}></use>
                </svg>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ValuesSectionParallax;
