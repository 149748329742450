import React from "react";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setEmail,
  setName,
  setError,
  selectForm,
  setSuccessMsg,
  resetForm,
} from "../store/formSlice";
import SVGSprite from "../assets/images/sprites.svg";
import { useEventCallback } from "usehooks-ts";
import { getFunctions, httpsCallable } from "firebase/functions";
import useReCaptcha from "../hooks/useReCaptcha";

const Footer = () => {
  const [isFormSubmitting, setFormSubmitting] = useState<boolean>(false);
  const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha();
  // disable warning about returning root state
  const userForm = useSelector(selectForm, {
    devModeChecks: {
      identityFunctionCheck: "never",
    },
  });
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement | null>(null);

  const handleSubmit = useEventCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setFormSubmitting(true);

      const token = await generateReCaptchaToken("signup");
      const functions = getFunctions();
      const sendEmail = httpsCallable(functions, "signUpEmail");
      sendEmail({
        email: userForm.email,
        name: userForm.name,
        token,
      })
        .then((result) => {
          dispatch(resetForm());
          dispatch(setSuccessMsg(`Waitlist joined`));
          setTimeout(() => {
            const msgs = document.querySelectorAll("p.error");
            if (msgs) {
              msgs.forEach((msg) => msg.classList.add("fadeOut"));
            }
          }, 5000);
        })
        .catch((err) => {
          dispatch(setError(`${err.code} - ${err.message} - ${err.details}`));
          dispatch(setSuccessMsg(null));
        })
        .finally(() => setFormSubmitting(false));
    }
  );

  return (
    <>
      <footer className="bg-black text-white md:px-10">
        <div className="px-4 md:pl-0 flex flex-col md:flex-row mx-auto pt-16">
          <section className="md:basis-1/2 md:shrink md:w-1/2 md:order-last">
            <h2 className="relative w-1/2 text-5xl pb-3 font-bold">
              let&rsquo;s be
              <br className="md:hidden" /> friends.
              <svg
                className="hidden absolute -top-4 -right-20 md:-right-14 md:hidden select-none rotate-heart"
                width="71"
                height="76"
                aria-label="BFFMobile Logo"
              >
                <use xlinkHref={`${SVGSprite}#crayon_heart`}></use>
              </svg>
            </h2>
            <p className="text-sm font-light w-1/2 md:w-full xl:w-7/12">
              Initial service will be available by invitation only. Give us your
              email so we can stay in touch! You&rsquo;ll get regular updates
              and have first access to this new, premium mobile service when we
              launch in 2024.
            </p>

            <div className="w-full lg:w-bff-form lg:flex-none pt-10">
              <form
                ref={formRef}
                onSubmit={handleSubmit}
                className="flex flex-col items-start w-full"
              >
                {userForm.error != null && (
                  <p className="error text-error">{userForm.error}</p>
                )}
                {userForm.success != null && (
                  <p className="error text-deep-indigo">{userForm.success}</p>
                )}
                <div className="flex flex-col w-full gap-2 mb-5 pr-4 lg:mb-0 relative">
                  <svg
                    className="absolute -top-8 right-0 hidden sm:-right-16 md:-right-18 lg:right-14 xl:right-10 select-none rotate-heart"
                    width="71"
                    height="76"
                  >
                    <use xlinkHref={`${SVGSprite}#crayon_heart`}></use>
                  </svg>
                  <input
                    autoComplete="given-name"
                    onChange={(e) => dispatch(setName(e.target.value))}
                    value={userForm.name}
                    id="nameFt"
                    type="text"
                    placeholder="Name"
                    className="form-input"
                    required
                  />
                  <input
                    autoComplete="email"
                    onChange={(e) => dispatch(setEmail(e.target.value))}
                    value={userForm.email}
                    id="emailFt"
                    type="email"
                    placeholder="Email"
                    className="form-input"
                    required
                  />
                </div>
                <button
                  type="submit"
                  disabled={!reCaptchaLoaded || isFormSubmitting}
                  className="waitlist mt-2 lg:mt-6"
                >
                  sign up
                </button>
              </form>
            </div>
          </section>

          <div className="w-full flex flex-col mx-auto md:basis-1/2 md:shrink md:w-1/2 md:pt-0 md:order-first select-none mt-20 md:mt-0">
            <Link
              className="w-logo h-logo inline-block mb-6 mt-4 md:mt-0"
              to="/"
            >
              <svg
                className="select-none"
                height="39"
                width="216"
                aria-label="BFFMobile Logo"
              >
                <use xlinkHref={`${SVGSprite}#bff_logo_inv`}></use>
              </svg>
            </Link>
            {/* <div className="mt-10 flex gap-6 md:gap-4 md:mt-0">
              <a
                href="#tbd"
                target="_blank"
                rel="noopener"
                title="Visit BFFMobile on Instagram"
              >
                <svg className="social-icon">
                  <use xlinkHref={`${SVGSprite}#insta`}></use>
                </svg>
              </a>
              <a
                href="#tbd"
                target="_blank"
                rel="noopener"
                title="Visit BFFMobile on LinkedIn"
              >
                <svg className="social-icon">
                  <use xlinkHref={`${SVGSprite}#linkedin`}></use>
                </svg>
              </a>
            </div> */}
          </div>
        </div>

        <div className="w-full flex flex-col mx-4 md:mx-auto">
          <div>
            <ul className="pt-20 text-footer select-none">
              <li className="legal-link">
                <Link to="/privacy">Privacy</Link>
              </li>
              {/* <li className="legal-link">
                <Link to="/terms">Terms</Link>
              </li> */}
            </ul>
          </div>
          <div className="pt-12 pb-6">
            <small>
              <span className="font-bold text-copyright">
                &copy;{new Date().getFullYear()} Copyright BFFMobile.
              </span>
              <span className="text-copyright font-light">
                {" "}
                All Rights Reserved.
              </span>
            </small>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
