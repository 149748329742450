import { createSlice } from "@reduxjs/toolkit";

interface FormState {
  name: string;
  email: string;
  error: string | null;
  success: string | null;
}
interface SignupPayload {
  type: string;
  payload: string;
}
interface StatusPayload {
  type: string;
  payload: string | null;
}

const initialState = {
  name: "",
  email: "",
  error: null,
  success: null,
};
export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setName: (state: FormState, action: SignupPayload) => {
      state.name = action.payload;
    },
    setEmail: (state: FormState, action: SignupPayload) => {
      state.email = action.payload;
    },
    setError: (state: FormState, action: StatusPayload) => {
      state.error = action.payload;
    },
    setSuccessMsg: (state: FormState, action: StatusPayload) => {
      state.success = action.payload;
    },
    resetForm: (state: FormState) => {
      state = initialState;
    },
  },
});

export const { setName, setEmail, setError, setSuccessMsg, resetForm } =
  formSlice.actions;
export const selectName = (state: FormState) => state.name;
export const selectEmail = (state: FormState) => state.email;
export const selectError = (state: FormState) => state.error;
export const selectForm = (state: FormState) => state;

export default formSlice.reducer;
